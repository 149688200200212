import React, { useState, useEffect } from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import './styles.css';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';


export default function SearchResults(props){
    const location = useLocation();
    const history = useHistory();
    const [users, setUsers] = useState();
    const [courses, setCourses] = useState();
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        api.get(`/search${location.search}&page=${page}`)
        .then(response => {
            if(response.data.user){
                setUsers(response.data.user);
                setCourses();
            }
            else if(response.data.course){
                setCourses(response.data.course);
                setUsers();
            }
            setLoading(false);
        })
    }, [page, location.search])
    
    if(loading)
        return null;

    return(
        <div>
            <div className="pageTitle">
                    <span>
                        Resultados da Busca
                    </span>
                </div>
            <div className="pageContent">
                {users ? 

                    (users.length > 0 ?

                    <table>
                    <tbody>
                        <tr>
                            <th>
                                <span>ID</span>
                            </th>
                            <th>
                                <span>Nome</span>
                            </th>
                            <th>
                                <span>Email</span>
                            </th>

                            <th>
                                <span>Inadimplente</span>
                            </th>
                        </tr>
                        {users.map(user => (
                            <tr key={user.id}>
                                <td>
                                    <span>{user.id}<br/></span>
                                </td>

                                <td>
                                    <Link to={`/admin/personal/${user.id}`} >
                                        {user.name}<br/>
                                    </Link>    
                                </td>

                                <td>
                                    <span>{user.email}<br/></span>
                                </td>

                                <td>
                                    <span>{user.isDefaulter ? 'Sim' : 'Não'}</span>  
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                    : "Nenhum resultado encontrado" )
                :   
                    (courses.length > 0 ?
                        <table>
                            <tr>
                                <th>
                                    <span>ID</span>
                                </th>
                                <th>
                                    <span>Nome</span>
                                </th>
                                <th>
                                    <span>Descrição</span>
                                </th>
                            </tr>
                            {courses.map(course => (
                                <tr key={course.id}>
                                    <td>
                                        <span>{course.id}</span>
                                    </td>

                                    <td>
                                        <span onClick={() => {
                                                history.push(`/course/${course.id}`);}}>
                                            {course.name}
                                        </span>
                                    </td>

                                    <td>
                                        <span>{course.description}</span>
                                    </td>
                                </tr>
                            ))}
                        </table>
                        : "Nenhum resultado encontrado" )
                    }

                    <div className="paginator">
                        <div className="arrows">
                            <div className="arrowLeft">
                                {page !== 0 ?
                                  <MdKeyboardArrowLeft size={30} color={'#537e43'} cursor={'pointer'} onClick={() =>
                                    {
                                        setPage(page - 1);
                                        }
                                    }/>
                                    : <MdKeyboardArrowLeft size={30} color={'black'}/>
                                }
                            </div>
                            
                            <div className="arrowRight">
                                {(courses? courses.length : users.length) >= 10 ?
                                  <MdKeyboardArrowRight size={30} style={{color:"#537e43"}} cursor={'pointer'} onClick={() =>
                                    {
                                        setPage(page + 1);
                                        }
                                    }/>
                                    : <MdKeyboardArrowRight size={30} color={'black'}/>
                                }
                            </div>
                        </div>
                    </div>
            </div>
        </div>
        );
}