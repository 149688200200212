function sortByName(array){
    let sortedList=[...array];

    sortedList.sort((a,b) =>{
        if(a.name.toLowerCase() <b.name.toLowerCase()){
            return -1;
        }
        if(a.name.toLowerCase()>b.name.toLowerCase()){
            return 1;
        }
        return 0;
    })

    return sortedList;
}

function sortById(array){
    let sortedList=[...array];

    sortedList.sort((a,b) =>{
        if(a.id<b.id){
            return -1;
        }
        if(a.id>b.id){
            return 1;
        }
        return 0;
    })

    return sortedList;
}

export function sortList(array, sortOption){
    var sortedList=[...array];
    if(sortOption==="Nome"){
        sortedList=sortByName(array);
    }else if(sortOption==="ID"){
        sortedList=sortById(array);
    }
    return sortedList;
}