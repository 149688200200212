import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './styles.css';
import {MdKeyboardBackspace} from 'react-icons/md';
import RenderUsers from '../RenderUsers';
import RegisterCourse from '../RegisterCourse';
import FileUpload from '../fileUpload';
import {ExportCSV} from '../ExportExcel';
import Cookies from 'js-cookie';

export default function CourseInformations(params){
    const [renderUsersAgain, setRenderUsersAgain] = useState(0);
    const user = Cookies.get('User') ? JSON.parse(Cookies.get('User')) : null;
    const {course_id} = useParams();
    const history = useHistory();
    console.log(user.league_function)

    //Nesta pagina tem um menu auxiliar que aparece no topo
    //Essa função muda o conteudo da pagina quando determinada opção é selecionada
    function changeTab(tab){
        const classes = ['editCourse', 'courseStudents', 'cadastrateStudent', 'leagueCourseStudents'];

        classes.forEach(element => {
            document.getElementsByClassName(element)[0].style.display = 'none';
        });

        document.getElementsByClassName(tab)[0].style.display = 'grid';

    }

    return(
        <div className="course">
            <div className="goBack">
                <MdKeyboardBackspace size={35} className="arrowBack" onClick={() => {
                history.goBack();
                 }} />

                <ul>
                    <li className= "editCourseTab" onClick={() => {
                            changeTab('editCourse');
                        }}>
                        <span>{user.isAdmin ? 'Editar' : 'Visualizar'} Curso</span>
                    </li>

                    {user.isAdmin ?

                        <>
                        <li className= "cadastrateStudentTab" onClick={() => {
                                changeTab('cadastrateStudent');
                            }}>
                            <span>Cadastrar Alunos</span>
                        </li>

                        <li className= "courseStudentsTab" onClick={() => {
                                changeTab('courseStudents');
                            }}>
                            <span>Alunos Cadastrados</span>
                        </li>
                        </>
                    : null
                    }

                    { user.league_function === "lider" ?
                        <li className= "leagueCourseStudentsTab" onClick={() => {
                                changeTab('leagueCourseStudents');
                            }}>
                            <span>Alunos da Liga</span>
                        </li>

                        :null
                    }
                </ul>
            </div>
            
            <div className="editCourse">
                <RegisterCourse course_id={course_id}/>
            </div>

            <div className="pageContent">
                <div className="cadastrateStudent commonWidth">
                    {user.isAdmin ?

                        <div className="cadastrateStudentContent">
                            <span>Faça o upload de um documento excel no formato .xlsx <br/><br/>Dica: É importante que este
                            documento contenha colunas com os seguintes nomes: "nome", "email", "cpf" e "qualidade do participante"
                            </span>
                            <FileUpload course_id={course_id} renderUsersAgain={renderUsersAgain} setRenderUsersAgain={setRenderUsersAgain}/>
                        </div>
                    : null
                    }
                </div>
            </div>
            <div className="courseStudents">
                {user.isAdmin ?
                <>
                    <ExportCSV course_id={course_id} type={"courseStudents"} fileName={"alunos_cadastrados"}/>
                    <RenderUsers course_id={course_id} renderUsersAgain={renderUsersAgain} removeFromCourseButton={true}/>
                </>
                : null
                }
            </div>

                    <div className="leagueCourseStudents">
                    { user.league_function === "lider" ?
                        <>
                            <ExportCSV course_id={course_id} type={"leagueCourseStudents"} fileName={"alunos_da_liga_cadastrados"} user_id={user.id}/>
                            <RenderUsers leagueUserCourse={true} course_id={course_id} user_id={user.id} />
                        </>
                    
                        : null
                    }
                    </div>  
        </div>
    )
};