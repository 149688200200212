import React, { useState } from 'react';
import './styles.css';
import ImageUpload from '../imageUpload';

export default function RegisterLogo(){
    const [name, setName] = useState('');

    return(
        <div className="registerLogo">
            <div className="pageTitle">
                <span>
                    Cadastrar Logo
                </span>
            </div>
            <div className="pageContent">
                <div className="registerLogoFields">
                    <label>Nome</label>
                    <input placeholder="Nome" value={name} onChange={e => setName(e.target.value)}/>
                    <label>Imagem</label>
                    <ImageUpload name={name} route={"/logo"}/>
                </div>
            </div>
        </div>
    )
};