import React, { useState } from 'react';
import './styles.css';
import ImageUpload from '../imageUpload';

export default function RegisterSignature(){
    const [name, setName] = useState('');

    return(
        <div className="registerSignature">
            <div className="pageTitle">
                <span>
                    Cadastrar Assinatura
                </span>
            </div>
            <div className="pageContent">
                <div className="registerSignatureFields">
                    <label>Nome</label>
                    <input placeholder="Nome" value={name} onChange={e => setName(e.target.value)}/>
                    <label>Imagem</label>
                    <ImageUpload name={name} route={"/signature"}/>
                </div>
            </div>
        </div>
    )
};