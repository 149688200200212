import React, { useState, useEffect } from 'react'
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';
import * as XLSX from 'xlsx';


//Este componente recebe um arquivo .xlsx e o converte para um array
export default function UploadFile(props) {
    const course_id = props.course_id;
    const renderUsersAgain = props.renderUsersAgain;
    const setRenderUsersAgain = props.setRenderUsersAgain;
    const [file, setFile] = useState();
    const [readed, setReaded] = useState(false);
    const [failedUploads, setFailedUploads] = useState([]);
    //Essa função recebe o nome do primeiro elemento de uma coluna
    //Caso esse elemento exista, retorna a coluna inteira
    function getColum() {
        let colum = {
            name: [], 
            email: [], 
            cpf: [], 
            userQuality: []
        };

        file.map((line, index) => {
            const foundUndefined = line.length === 4;
            
            if (foundUndefined) {
                for(let i = 0; i < 4; i++)
                    verifyUndefinedField(line[i].trim(), index, Object.keys(colum)[i])
                colum.name.push(line[0].trim());
                colum.email.push(line[1].trim());
                colum.cpf.push(line[2].trim());
                colum.userQuality.push(line[3].trim());
            } else {
                alert(`Número de colunas diferente de 4 na linha ${index + 1}`)
                throw new Error(`Problema na linha ${index + 1}`)
            }
            return null
        })

        return colum;
    }

    function verifyUndefinedField(data, index, colum){
        if(data === undefined){
            alert(`Campo vazio na linha ${index + 1} da coluna de ${colum}\nSiga o padrão de nomes da columa igual a nome, email, cpf, qualidade do participante`);
            throw new Error('Campo vazio')
        }
    }

    function verifyNamesColum(){
        const nameColumDefault = ['nome', 'email', 'cpf', 'qualidade do participante']
        
        
        const verificationFailed = file[0].find((line, index) => 
        {
            verifyUndefinedField(line, 0, nameColumDefault[index])
            return !(line.toLowerCase().includes(nameColumDefault[index]))
        });

        if(verificationFailed){
            alert('Nome das colunas estão errados');
            throw new Error('Erro no nome das colunas.')
        }
    }

    //Converte o arquivo em array e envia para o frontend fazer o cadastro dos alunos
    useEffect(() => {
        if (readed) {
            verifyNamesColum();
            let { name, email, cpf, userQuality } = getColum();
            console.log(name)
            let i = 0;
            try {
                while(i < cpf.length){
                    cpf[i] = cpf[i].replaceAll('.', '');
                    cpf[i] = cpf[i].replaceAll('-', '');
                    i++;
                }
             } catch (err) {
                alert(`Erro na formatação do cpf da linha ${i + 1}`)
                throw new Error(`Erro no cpf`);
            }
            console.log(cpf)
            setReaded(false);
            if (name && email && cpf && userQuality) {
                const request = [name, email, cpf, userQuality];
                const data = {
                    course_id,
                    fields: request
                }
                api.post('/addUserToCourse', data)
                    .then(res => {
                        setRenderUsersAgain(renderUsersAgain + 1);
                        setFailedUploads(res.data.errors);

                        if (res.data.errors.length === 0)
                            alert("Alunos cadastrados com Sucesso!");
                        else
                            alert("Alguns alunos não puderam ser cadastrados.");
                        document.getElementById("myFile").value = "";
                        setFile();
                    }).catch(err => {
                        document.getElementById("myFile").value = "";
                        setFile();
                        alert(`Erro: ${err}`);
                    })
            }
            else {
                alert("Falta um dos campos necessarios para o cadastro");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [file, readed, course_id, renderUsersAgain, setRenderUsersAgain]);

    function onFormSubmit(e) {
        e.preventDefault();

        var reader = new FileReader();

        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            const wsname = readedData.SheetNames[0];
            const ws = readedData.Sheets[wsname];

            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
            setFile(dataParse);
            setReaded(true);
        };
        reader.readAsBinaryString(file);

    }

    function onChange(e) {
        e.preventDefault();
        setFile(e.target.files[0]);
    }

    return (
        <div>
            <div className="send">
                <form onSubmit={e => onFormSubmit(e)}>
                    <input type="file" id="myFile" accept=".xlsx" name="excelFile" onChange={e => onChange(e)} />
                    <input type="submit" disabled={file ? false : true} value="Enviar" data="disable:true" />
                </form>
                <div className="errorStudents">
                    {failedUploads.length > 0 ?
                        <>
                            <hr />
                            <span>Os seguintes alunos não puderam ser cadastrados:</span>
                            <ul>
                                {failedUploads.map(user => (
                                    <li>{user}</li>
                                ))}
                            </ul>
                        </>
                        : null
                    }
                </div>
            </div>
        </div>

    );
}