import axios from '../services/api';
import Cookies from 'js-cookie';

axios.interceptors.request.use(function (config) {
    const token = Cookies.get('Authorization');
    config.headers.Authorization =  token;

    return config;
});

let isRefreshing = false;
 let failedQueue = [];

       const processQueue = (error, token = null) => {
            failedQueue.forEach(prom => {
                if (error) {
                    prom.reject(error);
                } else {
                    prom.resolve(token);
                }
            });

            failedQueue = [];
        };

axios.interceptors.response.use(
            response => {
                return response;
            },
err => {
                const originalRequest = err.config;

                if (err.response.status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise(function(resolve, reject) {
                            failedQueue.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                return axios(originalRequest);
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    return new Promise(function(resolve, reject) {
                        axios
                            .post(process.env.REACT_APP_API_URL + '/user/update_token', {
                                refreshToken: Cookies.get('refreshToken')})
                            .then(data => {
                                Cookies.set('Authorization', data.headers.authorization);
                                Cookies.set('refreshToken', data.data.refreshToken);
                                let isRefreshing = false;
                                let failedQueue = [];
                               
                                      const processQueue = (error, token = null) => {
                                           failedQueue.forEach(prom => {
                                               if (error) {
                                                   prom.reject(error);
                                               } else {
                                                   prom.resolve(token);
                                               }
                                           });
                               
                                           failedQueue = [];
                                       };
                               
                               axios.interceptors.response.use(
                                           response => {
                                               return response;
                                           },
                               err => {
                                               const originalRequest = err.config;
                               
                                               if (err.response.status === 401 && !originalRequest._retry) {
                                                   if (isRefreshing) {
                                                       return new Promise(function(resolve, reject) {
                                                           failedQueue.push({ resolve, reject });
                                                       })
                                                           .then(token => {
                                                               originalRequest.headers['Authorization'] = 'Bearer ' + token;
                                                               return axios(originalRequest);
                                                           })
                                                           .catch(err => {
                                                               return Promise.reject(err);
                                                           });
                                                   }
                               
                                                   originalRequest._retry = true;
                                                   isRefreshing = true;
                               
                                                   return new Promise(function(resolve, reject) {
                                                       axios
                                                           .post(process.env.REACT_APP_API_URL + '/user/update_token', {
                                                               refreshToken: Cookies.get('refreshToken')})
                                                           .then(data => {
                                                               axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.headers.authorization;
                                                               originalRequest.headers['Authorization'] = 'Bearer ' + data.headers.authorization;
                                                               Cookies.set('Authorization', data.headers.authorization);
                                                               Cookies.set('refreshToken', data.data.refreshToken);
                                                               processQueue(null, data.headers.authorization);
                                                               resolve(axios(originalRequest));
                                                           })
                                                           .catch(err => {
                                                               processQueue(err, null);                               
                                                               reject(err);
                                                           })
                                                           .then(() => {
                                                               isRefreshing = false;
                                                           });
                                                   });
                                               }
                               
                                               return Promise.reject(err);
                                           }
                                       );              axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.headers.authorization;
                                originalRequest.headers['Authorization'] = 'Bearer ' + data.headers.authorization;
                                processQueue(null, data.headers.authorization);
                                resolve(axios(originalRequest));
                            })
                            .catch(err => {
                                processQueue(err, null);

                                reject(err);
                            })
                            .then(() => {
                                isRefreshing = false;
                            });
                    });
                }

                return Promise.reject(err);
            }
        );