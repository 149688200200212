import React, { useState } from 'react';
import {useHistory} from 'react-router-dom';
import api from '../../services/api';
import './styles.css'
import "../../utils/axiosInterceptor";
import { toast } from 'react-toastify';


export default function ForgottenPassword() {
    const [email, setEmail] = useState('');
    const history = useHistory();

    function forgottenPassword(e){
        e.preventDefault();

        api.post('/user/forgottenPassword', {
            user_email: email
        })
        .then(reponse => {
            toast.success('Email de recuperação enviado com sucesso', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
            history.push('/login');
        })
        .catch(err => {
            toast.error('Email não encontrado', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
            });
        })
    }


    return (
        <div className="forgottenPasswordFields">
            <form onSubmit={forgottenPassword}>
                <h1>Esqueci minha senha</h1>
                <h4>Te enviaremos um link para resetar sua senha</h4><br/><br/>
                <input
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                />
                <button className="sendButton" type="submit">Enviar</button>
            </form>
        </div>
    )
}
