import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import './styles.css';
import {MdKeyboardBackspace} from 'react-icons/md';
import RenderUsers from '../RenderUsers';
import RegisterLeague from '../RegisterLeague';
import api from '../../services/api';
import "../../utils/axiosInterceptor";

export default function LeagueInformations(params){
    const [renderUsersAgain, setRenderUsersAgain] = useState(0);
    const {league_id} = useParams();
    const [userEmail, setUserEmail] = useState('');
    const history = useHistory();

    //Nesta pagina tem um menu auxiliar que aparece no topo
    //Essa função muda o conteudo da pagina quando determinada opção é selecionada
    function changeTab(tab){
        const classes = ['editLeague', 'leagueStudents', 'cadastrateStudent'];

        classes.forEach(element => {
            document.getElementsByClassName(element)[0].style.display = 'none';
        });

        document.getElementsByClassName(tab)[0].style.display = 'block';

    }

    async function cadastrateStudent(e){
        e.preventDefault();
        const data = {
            league_id,
            userEmail
        }

        await api.post("/addUserToLeague", data)
        .then(() => {
            setUserEmail('');
            setRenderUsersAgain(renderUsersAgain + 1);
        }).catch(() => {
            alert("Nao foi possivel cadastrar o usuario com o email indicado");
        })
    }

    return(
        <div className="league">
            <div className="goBack">
                <MdKeyboardBackspace size={35} className="arrowBack" onClick={() => {
                history.goBack();
                 }} />

                <ul>
                    <li className= "editLeagueTab" onClick={() => {
                            changeTab('editLeague');
                        }}>
                        <span>Editar Liga</span>
                    </li>

                    <li className= "cadastrateStudentTab" onClick={() => {
                            changeTab('cadastrateStudent');
                        }}>
                        <span>Cadastrar Alunos</span>
                    </li>

                    <li className= "leagueStudentsTab" onClick={() => {
                            changeTab('leagueStudents');
                        }}>
                        <span>Alunos Cadastrados</span>
                    </li>
                </ul>
            </div>

            
            <div className="editLeague">
                <RegisterLeague league_id={league_id}/>
            </div>
            <div className="pageContent">
                <div className="cadastrateStudent">
                    <div className="cadastrateStudentContent">
                        <form onSubmit={cadastrateStudent}>
                            <span>Email do aluno a ser cadastrado
                            </span>
                            <input type="text" value={userEmail} onChange={e => setUserEmail(e.target.value)}/>
                            <div className="registerStudent">
                            <input type="submit" value="Cadastrar" />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="leagueStudents">
                <RenderUsers league_id={league_id} renderUsersAgain={renderUsersAgain} />
            </div>
            
        </div>
    )
};