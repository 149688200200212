
export function filter(users, query) {
        var filteredUsers = [];
        if (query.length === 0) {
            return users;
        } else {
            for (var i = 0; i < users.length; i++) {
                query = query.toLowerCase();
                var name = users[i].name.toLowerCase();

                if (name.includes(query)) {
                    filteredUsers.push(users[i]);
                }
            }

            return filteredUsers;
        }
    }