
import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import "./styles.css";

export const ExportCSV = ({course_id, fileName, type, user_id}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const fetchData = (course_id, type, user_id) => {
        let users;
        if(type === "courseStudents"){
            api.get("/allCourseUsers", {
                params: {
                    course_id: course_id
                }
            })
            .then(response => {
                users = response.data.users;
                createExcel(users);
            })
            .catch(() => {
                alert("ocorreu um erro");
            })
        }
        else{
            api.get("/allLeagueUsers", {
                params: {
                    course_id: course_id,
                    user_id: user_id
                }
            })
            .then(response => {
                users = response.data.users;
                createExcel(users);
            })
            .catch(() => {
                alert("ocorreu um erro");
            })
        }
    }

    const createExcel = (users) => {
            users.forEach(user =>  {
                delete user.id;
                delete user.league_id;
                delete user.league_function;
                delete user.password;
                user.Nome = user.name;
                delete user.name;
                user.Email = user.email;
                delete user.email;
                user.CPF = user.cpf;
                delete user.cpf;
                user.Inadimplente = user.isDefaulter;
                delete user.isDefaulter;

            });
            exportToCSV(users, fileName)
    }

    return (
        <Button className="downloadButton" onClick={(e) => fetchData(course_id, type, user_id)}>Baixar Lista</Button>
    )
}