import React, { useEffect, useState } from 'react';
import './styles.css';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import Loader from 'react-loader-spinner';
import {GoSearch} from 'react-icons/go';
import {DebounceInput} from 'react-debounce-input';
import { toast } from 'react-toastify';



import { filter } from '../../utils/filter'
import { sortList } from '../../utils/sortList'

export default function RenderUsers(props) {
    const [logos, setLogos] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState("ID");
    const [query, setQuery] = useState("");

    useEffect(() => {
        setLogos(sortList(logos, sortBy));

        // eslint-disable-next-line
    }, [sortBy]);

    //Renderiza todos os logos
    useEffect(() => {
        if(logos === [])
            setLoading(true);
        api.get(`/searchLogo?search=${query}&page=${page}`)
            .then(response => {
                setLogos(sortList(response.data.logos, sortBy));
                setLoading(false);
            })

            // eslint-disable-next-line
    }, [page, query])

    function removeLogo(e, removed_logo_id) {
        e.preventDefault();

        if (window.confirm("Tem certeza de que deseja excluir este logo? A ação é irreversível.")) {
            api.delete(`/logo/${removed_logo_id}`)
                .then(response => {
                    let auxLogos = logos;
                    let indexLogo = auxLogos.indexOf(auxLogos.find(logo => logo.id === removed_logo_id))
                    auxLogos.splice(indexLogo, 1);
                    setLogos([...auxLogos]);
                    toast.success('Logo excluída com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }).catch(err => {
                    toast.error('Não foi possível excluir a logo', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                })
        }
    }

    if(loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);
    return(
        <div className="logoInformations">
            <div className="pageTitle">
                <span>
                    Todos os Logos
                </span>
            </div>

            <div className="pageContent">
                <div className="searchOrder">
                    <div className="searchBar">
                        <DebounceInput debounceTimeout={350} type="text" id="search" placeholder="Pesquisar Logo" label="search logo" onChange={e => setQuery(e.target.value)} />
                        <GoSearch size={30} style={{margin: 'auto', marginLeft: '-35px', color: '#a3a7ae'}}/>
                    </div>
                    <div className="selectBoxContainer">
                        <span>Ordernar Por</span>
                        <select onChange={e => setSortBy(e.target.value)}>
                            <option selected='true' value={"ID"} >ID</option>
                            <option value={"Nome"} >Nome</option>
                        </select>

                    </div>

                </div>
                <div className="listTable">
                    {logos.length > 0 ?
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>
                                        <span>ID</span>
                                    </th>
                                    <th>
                                        <span>Nome</span>
                                    </th>
                                    <th>
                                        <span>Excluir</span>
                                    </th>
                                </tr>
                                {filter(logos, query).map(logo => (
                                    <tr key={logo.id}>
                                        <td>
                                            <span>{logo.id}<br /></span>
                                        </td>

                                        <td>
                                            <a href={`${logo.image_path}`} target='_blank' rel="noopener noreferrer">
                                                {logo.name}<br />
                                            </a>
                                        </td>

                                        <td>
                                            <button onClick={e => removeLogo(e, logo.id)}>
                                                <img className="removeButton" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="remove-logo"></img>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {logos.length !== 0 &&
                                    <>
                                        <div className="navigation">
                                            <div className="previous">
                                                {page !== 0 ?
                                                    <MdKeyboardArrowLeft size={30} color={'#537e43'} cursor={'pointer'} onClick={() => {
                                                        setPage(page - 1);
                                                    }} />
                                                    : <MdKeyboardArrowLeft size={30} color={'black'} />
                                                }
                                            </div>

                                            <div className="next">
                                                {logos.length >= 10 ?
                                                    <MdKeyboardArrowRight size={30} style={{ color: "#537e43" }} cursor={'pointer'} onClick={() => {
                                                        setPage(page + 1);
                                                    }} />
                                                    : <MdKeyboardArrowRight size={30} color={'black'} />
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </tbody>
                        </table>
                        : "Nenhum logo encontrado"
                    }

                </div>
            </div >
        </div >
    );
}