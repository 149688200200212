import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';


export default function RegisterLeague(props){
    const league_id = props.league_id;
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    //Esta rota tambem pode ser utilizada para editar um curso.
    //Portanto, se for passado para rota um user_id, as informações do curso
    //serao buscadas e editadas
    useEffect(() => {
        setLoading(true);
        if(league_id){
            api.get(`/league/${league_id}`)
            .then(response => {
                const league = response.data.league;
                setName(league.name);
                setDescription(league.description);
                setLoading(false);
            })
        }
        else
            setLoading(false);
    }, [league_id])

    function register(e){
        e.preventDefault();

        if(league_id){
            const data = {
                name, 
                description,
                league_id
            }
            api.put('/league', data)
            .then(() => {
                toast.success('Liga alterada com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    });
            })
            .catch(err => {
                toast.error('Erro ao cadastrar a liga, tente novamente.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    });
        })
        } else{
            const data = {
                name, 
                description,
            }
            api.post('/league', data)
            .then(response => {
                toast.success('Liga cadastrado com sucesso', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    });
                history.push(`/admin/league/${response.data.league[0]}`);

            })
            .catch(err => {
                toast.error('Erro ao cadastrar a liga, tente novamente.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    });
            }
            )}
    }

    function deleteLeague(e) {
        e.preventDefault();

        if (window.confirm("Tem certeza de que deseja excluir a liga? A ação é irreversível.")) {
            api.delete(`/league/${league_id}`)
                .then(response => {
                    history.push("/admin/leagues");
                }).catch(err => {
                    alert(`Não foi possível deletar a liga. ${err}`);
                })
        }
    }

    if(loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);

    return(
        <div className="registerLeague">
            {!league_id && <div className="pageTitle">
                <span>
                    Registrar Liga
                </span>
            </div>}
            <div className="pageContent">
                <div className="">
                    <form onSubmit={register}>
                        <label>Nome</label>
                        <input value={name} onChange={e => setName(e.target.value)}/>

                        <label>Descrição</label>
                        <textarea value={description} onChange={e => setDescription(e.target.value)}/>
                        <div className="buttonGroup">
                                {league_id ? 
                                    <>
                                    <button className="removeLeagueButton" onClick={deleteLeague}>
                                        <img className="removeLeague" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="remove-league"></img>
                                    </button>
                                    </>
                                    : <div></div>
                                }
                                <input type="submit" value={league_id ? "Editar" : "Cadastrar" } />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
};