import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';

export default function Auth(){
    const token = Cookies.get('Authorization') ? Cookies.get('Authorization') : null;
    let userAuthenticated = false;
    let adminAuthenticated = false;
    let leagueLiderAuthenticated = false;
    if(token){
        const payload = jwt.decode((token.split(" ")[1]));
        if(payload.exp >= Date.now() / 1000){
            userAuthenticated = true;
            if(payload.isAdmin)
                adminAuthenticated = true;
            if(payload.league_function === 'lider')
                leagueLiderAuthenticated = true;
        }
    }
    return {userAuthenticated, adminAuthenticated, leagueLiderAuthenticated}
}