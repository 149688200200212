import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import Loader from 'react-loader-spinner';
import { filter } from '../../utils/filter'
import { sortList} from '../../utils/sortList'
import {GoSearch} from 'react-icons/go';
import {DebounceInput} from 'react-debounce-input';



export default function RenderLeagues(props) {
    const [leagues, setLeagues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState("ID");
    const [query, setQuery] = useState("");

    useEffect(() => {
        setLeagues(sortList(leagues, sortBy));

        // eslint-disable-next-line
    }, [sortBy]);

    //Renderiza todas as ligas
    useEffect(() => {
        if(leagues === [])
            setLoading(true);
        api.get(`/searchLeague?search=${query}&page=${page}`)
            .then(response => {
                setLeagues(sortList(response.data.leagues, sortBy));
                setLoading(false);
            })
        
            // eslint-disable-next-line
    }, [page, query])

    if (loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);
    
    return (
        <div className="renderLeagues">
            <div className="pageTitle">
                <span>
                    Todas as Ligas
                </span>
            </div>
            <div className="pageContent">
                <div className="searchOrder">
                    <div className="searchBar">
                        <DebounceInput debounceTimeout={350} type="text" id="search" placeholder="Pesquisar Liga" label="search league" onChange={e => {
                            setQuery(e.target.value);
                            }} />
                        <GoSearch size={30} style={{margin: 'auto', marginLeft: '-35px', color: '#a3a7ae'}}/>
                    </div>
                    <div className="selectBoxContainer">
                        <span>Ordernar Por</span>
                        <select onChange={e => setSortBy(e.target.value)}>
                            <option selected='true' value={"ID"} >ID</option>
                            <option value={"Nome"} >Nome</option>
                        </select>

                    </div>

                </div>
                <div className="listTable">
                    {leagues.length > 0 ?
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>
                                        <span>ID</span>
                                    </th>
                                    <th>
                                        <span>Nome</span>
                                    </th>
                                    <th>
                                        <span>Descrição</span>
                                    </th>
                                </tr>
                                {filter(leagues, query).map(league => (
                                    <tr key={league.id}>
                                        <td>
                                            <span>{league.id}<br /></span>
                                        </td>

                                        <td>
                                            <Link to={`/admin/league/${league.id}`} >
                                                {league.name}<br />
                                            </Link>
                                        </td>

                                        <td>
                                            <span>{league.description}<br /></span>
                                        </td>
                                    </tr>
                                ))}
                                {leagues.length !== 0 &&
                                    <>
                                        <div className="navigation">
                                            <div className="previous">
                                                {page !== 0 ?
                                                    <MdKeyboardArrowLeft size={30} color={'#537e43'} cursor={'pointer'} onClick={() => {
                                                        setPage(page - 1);
                                                    }} />
                                                    : <MdKeyboardArrowLeft size={30} color={'black'} />
                                                }
                                            </div>

                                            <div className="next">
                                                {leagues.length >= 10 ?
                                                    <MdKeyboardArrowRight size={30} style={{ color: "#537e43" }} cursor={'pointer'} onClick={() => {
                                                        setPage(page + 1);
                                                    }} />
                                                    : <MdKeyboardArrowRight size={30} color={'black'} />
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                            </tbody>
                        </table>
                        : "Nenhuma liga cadastrada"
                    }

                </div>
            </div>
        </div>
    );
}