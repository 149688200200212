import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Cookies from 'js-cookie'
import { checkUserRegisterInput } from '../../Validate/checkUser.js';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';



import './styles.css'

import api from '../../services/api';
import "../../utils/axiosInterceptor";

export default function Register() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');
    const [fromUniversity, setFromUniversity] = useState(true);
    const [password, setPassword] = useState('');

    const history = useHistory();

    async function register(e) {
        e.preventDefault();

        let data;
        data = {
            name,
            email,
            cpf,
            password,
            fromUniversity
        }
        var error = checkUserRegisterInput(data);



        if (error.length === 0) {
            await api.post('/register', data)
                .then(response => {
                    Cookies.set('User', response.data.response)
                    history.push('/login');
                })
                .catch(err => {
                    toast.warn("Um erro ocorreu. Verifique seus dados e tente novamente", {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    })
                })
        } else {
            error.map(err => (
                toast.warn(err, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                })
            ));
        }


    }


    return (
        <form onSubmit={register} className="registerForm">
            <h1>Cadastro</h1>
            <input 
            placeholder="Nome"
            value={name}
            onChange={e => setName(e.target.value)}
            />
            <input 
            placeholder="Email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            />


            <select id="fromUniversity" name="fromUniversity" onChange={e => setFromUniversity(e.target.value === 'true')} >
                <option value='true'>Estudante da Universidade</option>
                <option value='false'>Estudante de fora da Universidade</option>
            </select>

            <NumberFormat placeholder="CPF" value={cpf} format="###.###.###-##" onValueChange={(values) => {
                setCpf(values.value);
            }}></NumberFormat>


            <input 
            type="password"
            placeholder="Senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            />

            <button className="registerButton" type="submit">Cadastrar</button>

            <div className="registerRedirectText">
                <span>Já possui uma conta? <Link to='/login' className="reactLink">Entrar</Link></span>
            </div>
        </form>
    )
}
