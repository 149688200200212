import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import { toast } from 'react-toastify';
import './styles.css'

import api from '../../services/api';
import "../../utils/axiosInterceptor";
import { checkUserLogin } from '../../Validate/checkUser';
import jwt from 'jsonwebtoken';

export default function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const history = useHistory();

    async function login(e) {
        e.preventDefault();

        const data = {
            email,
            password,
        }
        var error = checkUserLogin(data);
        if (error.length === 0) {
            await api.post('/login', data)
                .then(response => {
                    Cookies.set('Authorization', response.headers.authorization);
                    Cookies.set('User', jwt.decode(Cookies.get('Authorization').split(" ")[1]));
                    Cookies.set('refreshToken', response.data.refreshToken);
                    history.push('/user/courses');
                })
                .catch(error => {
                    toast.error('Email ou senha inválidos', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                        });
                })
        } else {
            error.map(err => (
                toast.warn(err, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                    progress: undefined,
                    })
            ));
        }

    }

    return (
        <form onSubmit={login}>
            <h1>Login</h1>
            <input
                placeholder="Email"
                value={email}
                onChange={e => setEmail(e.target.value)}
            />
            <input
                placeholder="Senha"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <button className="loginButton" type="submit">Entrar</button>

            <div className="loginRedirectText">
                <span>Ainda não tem uma conta? <Link className="reactLink" to='/register'>Cadastre-se</Link></span>
                <span><Link className="reactLink" to='/forgottenPassword'>Esqueceu sua senha?</Link></span>
            </div>
        </form>
    )
}
