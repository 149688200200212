const helpers = {
    getQueryParam: function(name, location){
        const search = location.search;
        if(search.split(`?${name}=`)[1])
            return search.split(`?${name}=`)[1].split('&')[0];
        else if(search.split(`&${name}=`)[1])
            return search.split(`&${name}=`)[1].split('&')[0];
        else 
            return 0;
    }
}

export default helpers;