import * as validation from './validation';

export function checkCourseInput(input, topicsLines) {

    var message = [];
    if (!validation.validateWorkload(input.workload)) {

        message.push("Carga horária inválida");
    }

    if (topicsLines > 38) {
        message.push("Seus tópicos excedem o numero total de linhas");
    }

    return message;
}



