import * as validation from './validation';

//Verifica os campos do usuario
export function checkUserInput(input) {
    var message = [];
    if (!validation.validateName(input.name)) {
        message.push("Nome inválido (Deve conter apenas letras)");
    }
    if (!(validation.validateEmail(input.email)) && input.email.length > 0) {
        message.push("Email inválido");
    }

    //se for aluno da universidade, cpf obrigatorio
    if (!validation.validateCPF(input.cpf)) {
        message.push("CPF Inválido (CPF Obrigatório)");
    }
    return message;

}

//verifica os campos de um usuario ao registrar
export function checkUserRegisterInput(input) {
    // Mantém as validações de checkUserInput
    const message = checkUserInput(input);
    const password = input.password;

    // Valida se a senha tem no mínimo 8 caracteres
    if (password.length < 8) {
        message.push("Senha inválida (Senha deve ter no mínimo 8 dígitos)");
    }

    // Verifica se contém ao menos um caractere minúsculo
    if (!/[a-z]/.test(password)) {
        message.push("Senha inválida (Deve conter ao menos uma letra minúscula)");
    }

    // Verifica se contém ao menos um caractere maiúsculo
    if (!/[A-Z]/.test(password)) {
        message.push("Senha inválida (Deve conter ao menos uma letra maiúscula)");
    }

    // Verifica se contém ao menos um número
    if (!/[0-9]/.test(password)) {
        message.push("Senha inválida (Deve conter ao menos um número)");
    }

    // Verifica se contém ao menos um caractere especial
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
        message.push("Senha inválida (Deve conter ao menos um caractere especial)");
    }

    return message;
}


//verifica os campos de login de um usuario
export function checkUserLogin(input) {
    var message = [];
    if (input.password.length < 8) {
        message.push("Senha inválida (Senha deve ter no mínimo 8 dígitos)");
    }
    if (!validation.validateEmail(input.email)) {
        message.push("Email Inválido");
    }
    return message;
}

