import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import Cookies from 'js-cookie';
import './styles.css';
import Loader from 'react-loader-spinner';
import jwt from 'jsonwebtoken';
import ReactPaginate from 'react-paginate';


export default function RenderCourses(props){
    const user_id = props.user_id;
    const { renderAll } = useParams();
    const [coursesTotal, setCoursesTotal] = useState(0);
    const [courses, setCourses] = useState([]);
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [page, setPage] = useState(0);
    const PER_PAGE = 10;
    const showDisplayPhone = window.innerWidth < 1024;

    
    //Este componente é usado em dois casos: para renderizar todos os cursos
    //e para renderizar os cursos de um usuario. Por isso o parametro renderAll
    useEffect(() => {
        setLoading(true);
        async function getTotalData(){
            const [ countCoursesResponse, coursesResponse ] = await Promise.all([
                api.get('/courseCount'),
                api.get('/course', {
                    params: {
                        page: page
                    }
                })
            ])

            setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE))
            setCourses(coursesResponse.data.courses)
            setLoading(false)
        }

        async function getUserData(){
            const [ countCoursesResponse, coursesResponse ] = await Promise.all([
                api.get('/userCoursesCount', {
                    params: {
                        user_id: user_id ? user_id : JSON.parse(Cookies.get('User')).id,
                    }}),
                api.get('/userCourses', {
                    params: {
                        user_id: user_id ? user_id : JSON.parse(Cookies.get('User')).id,
                        page: page
                    }}),
            ])

            setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE))
            setCourses(coursesResponse.data.courses)
            setLoading(false)
        }
        async function getTotalDataSearch(){
            const [ countCoursesResponse, coursesResponse ] = await Promise.all([
                api.get('/searchCoursesCount', {
                    params: {
                        name: search,
                    }
                }),
                api.get('/searchCourses', {
                    params: {
                        name: search,
                        page,
                    }
                })
            ])
            setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE))
            setCourses(coursesResponse.data.courses)
            setLoading(false)
        }

        async function getUserDataSearch(){
            const [ countCoursesResponse, coursesResponse ] = await Promise.all([
                api.get('/searchUserCoursesCount', {
                    params: {
                        name: search,
                        user_id: user_id ? user_id : JSON.parse(Cookies.get('User')).id,
                    }
                }),
                api.get('/searchUserCourses', {
                    params: {
                        name: search,
                        page,
                        user_id: user_id ? user_id : JSON.parse(Cookies.get('User')).id,
                    }
                })
            ])
            setCoursesTotal(Math.ceil(countCoursesResponse.data[0].count / PER_PAGE))
            setCourses(coursesResponse.data.courses)
            setLoading(false)
        }

        if(renderAll){
            if(search)
                getTotalDataSearch()
            else
                getTotalData()
        }
        else{
            if(search)
                getUserDataSearch()
            else
                getUserData()
        }
        window.scrollTo(0, 0);
    }, [renderAll, page, user_id, search]);

    // Invoke when user click to request another page.
    function handlePageClick(event) {
        setPage(event.selected)
    };

    async function handleForm(e){
        e.preventDefault()
        setPage(0)
        setSearch(document.querySelector('#pesquisar').value.trim())
    }

    return(
        <div className="courses">
            {!user_id ?
                <div className="pageTitle">
                    <span>
                        {renderAll ? 'Todos os Cursos' : 'Meus Cursos' }
                    </span>

                    <form className="form" onSubmit={handleForm}>
                        <input type="text" id="pesquisar" name="pesquisar" className="inputForm"/>
                        <button type="submit">Pesquisar</button>
                    </form>
                </div>
            : null}
            <div className="pageContent">
                {loading ? <div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>
                : courses.length !== 0 ?
                    <div className="coursesRender">
                        {courses.map(course => (
                            <div className="card" id="cardCourse" key={`course${course.id}`}>
                                <div className="card-header" id="cardHeaderCourse">
                                    <span onClick={() => {
                                        history.push(`/user/course/${course.id}`)
                                    }}>{course.name}</span>
                                </div>
                                <div className="card-body" id="cardBodyCourse">
                                    <span>{course.description}</span>
                                </div>
                                <div className="card-footer" id="cardFooterCourse">
                                    <span onClick={() => {
                                        history.push(`/certification?code=${jwt.sign({course_id: course.id, user_id: user_id ? user_id : JSON.parse(Cookies.get('User')).id}, 'secret1')}&`)
                                    }}>Ver Certificado</span>
                                </div>
                            </div>
                        ))}

                        
                    </div>
                : <span>Não há mais cursos</span>
                }            

                {courses.length !== 0 &&
                <div className="paginator">
                   <ReactPaginate
                        breakLabel={showDisplayPhone ? null : '...'}
                        nextLabel={showDisplayPhone ? ">" : "next >"}
                        onPageChange={handlePageClick}
                        forcePage={page}
                        pageRangeDisplayed={showDisplayPhone ? 2 : 6}
                        pageCount={coursesTotal}
                        className={showDisplayPhone ? 'paginatorComponent' : null}
                        previousLabel={showDisplayPhone ? "<" : "< previous"}
                        renderOnZeroPageCount={null}
                        pageClassName={showDisplayPhone ? 'itemPaginateButton' : 'page-item'}
                        pageLinkClassName={showDisplayPhone ? 'itemPaginateText' : 'page-link'}
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </div>
            }
            </div>
        </div>
    )
};