import React, { useEffect, useState } from 'react';
import './styles.css';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import Loader from 'react-loader-spinner';
import {GoSearch} from 'react-icons/go';
import {DebounceInput} from 'react-debounce-input';
import { toast } from 'react-toastify';



import { filter } from '../../utils/filter'
import {sortList} from '../../utils/sortList'

export default function RenderUsers(props) {
    const [signatures, setSignatures] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [sortBy, setSortBy] = useState("ID");
    const [query, setQuery] = useState("");

    useEffect(()=> {
        setSignatures(sortList(signatures,sortBy));

        // eslint-disable-next-line
    },[sortBy]);

    //Renderiza todos as assinaturas
    useEffect(() => {
        if(signatures === [])
            setLoading(true);
        api.get(`/searchSignature?search=${query}&page=${page}`)
            .then(response => {
                setSignatures(sortList(response.data.signatures, sortBy));
                setLoading(false);
            })
            // eslint-disable-next-line
    }, [page, query])

    function removeSignature(e, removed_signature_id) {
        e.preventDefault();

        if (window.confirm("Tem certeza de que deseja excluir esta assinatura? A ação é irreversível.")) {
            api.delete(`/signature/${removed_signature_id}`)
                .then(response => {
                    let auxSignatures = signatures;
                    let indexSignature = auxSignatures.indexOf(auxSignatures.find(signature => signature.id === removed_signature_id))
                    auxSignatures.splice(indexSignature, 1);
                    setSignatures([...auxSignatures]);
                    toast.success('Assinatura excluída com sucesso', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });
                }).catch(err => {
                    toast.error('Não foi possível excluir a assinatura', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: undefined,
                    });                })
        }
    }

    if(loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'}/> </div>);
    return(
        <div className="signatureInformations">
            <div className="pageTitle">
                <span>
                    Todas as Assinaturas
                </span>
            </div>

            <div className="pageContent">
                <div className="searchOrder">
                    <div className="searchBar">
                        <DebounceInput debounceTimeout={350} type="text" id="search" placeholder="Pesquisar Assinatura" label="search signature" onChange={e => setQuery(e.target.value)} />
                        <GoSearch size={30} style={{margin: 'auto', marginLeft: '-35px', color: '#a3a7ae'}}/>
                    </div>
                    <div className="selectBoxContainer">
                        <span>Ordernar Por</span>
                        <select onChange={e => setSortBy(e.target.value)}>
                            <option selected='true' value={"ID"} >ID</option>
                            <option value={"Nome"} >Nome</option>
                        </select>

                    </div>

                </div>
                <div className="listTable">
                    {signatures.length > 0 ?
                        <table className="table">
                            <tbody>
                                <tr>
                                    <th>
                                        <span>ID</span>
                                    </th>
                                    <th>
                                        <span>Nome</span>
                                    </th>
                                    <th>
                                        <span>Excluir</span>
                                    </th>
                                </tr>
                                {filter(signatures, query).map(signature => (
                                    <tr key={signature.id}>
                                        <td>
                                            <span>{signature.id}<br /></span>
                                        </td>

                                        <td>
                                            <a href={`${signature.image_path}`} target='_blank' rel="noopener noreferrer">
                                                {signature.name}<br />
                                            </a>
                                        </td>

                                        <td>
                                            <button onClick={e => removeSignature(e, signature.id)}>
                                                <img className="removeButton" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt="remove-signature"></img>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                {signatures.length !== 0 &&
                                    <div className="navigation">
                                        <div className="previous">
                                            {page !== 0 ?
                                                <MdKeyboardArrowLeft size={30} color={'#537e43'} cursor={'pointer'} onClick={() => {
                                                    setPage(page - 1);
                                                }} />
                                                : <MdKeyboardArrowLeft size={30} color={'black'} />
                                            }
                                        </div>

                                        <div className="next">
                                            {signatures.length >= 10 ?
                                                <MdKeyboardArrowRight size={30} style={{ color: "#537e43" }} cursor={'pointer'} onClick={() => {
                                                    setPage(page + 1);
                                                }} />
                                                : <MdKeyboardArrowRight size={30} color={'black'} />
                                            }
                                        </div>
                                    </div>
                                }
                            </tbody>
                        </table>
                        : "Nenhuma assinatura encontrado"
                    }

                </div>
            </div>
        </div>
    );
}