import React from 'react'
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';


//Este componente recebe o upload de uma imagem e a envia para o backend
class ReactUploadImage extends React.Component {

    constructor(props) {
        super(props);
        this.state ={
            file: null,
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onFormSubmit(e){
        e.preventDefault();
        const name = this.props.name;
        const route = this.props.route;
        const formData = new FormData();
        formData.append('name', name);
        if(this.state.file)
            formData.append('file', this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            }
        };
        
        api.post(route, formData,config)
        .then(() => {
            alert(`${route === "/logo" ? "Logo" : "Assinatura"} cadastrada com sucesso!`);
        })
        .catch((error) => {
            alert(`${route === "/logo" ? "Logo" : "Assinatura"} nao pode ser cadastrada. Verifique se já não existe algo com esse nome e se o arquivo está correto`);
        });
    }
    onChange(e) {
        this.setState({file: e.target.files[0]});
 
    }

    render() {
        return (
            <div className="send">
                <form onSubmit={this.onFormSubmit}>
                    <input type="file" multiple accept=".png, .jpeg, .jpg" name="myImage" onChange= {this.onChange} />
                    <input type="submit" data="disable:true" value="Registrar" />
                </form>
            </div>
        )
    }
}

export default ReactUploadImage