import React, { useState, useEffect } from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import './styles.css';
import UserRoutes from '../../routes/userRoutes';
import AdminRoutes from '../../routes/adminRoutes';
import Sidebar from '../Sidebar';
import "../../utils/axiosInterceptor";
import Auth from '../../hooks/auth';


export default function Dashboard(){
    const [loading, setLoading] = useState(true);
    const [authenticatedUser, setAuthenticatedUser] = useState(false);
    const [authenticatedAdmin, setAuthenticatedAdmin] = useState(false);

    
    useEffect(() => {
        const authentication = Auth();
        setAuthenticatedUser(authentication.userAuthenticated);
        setAuthenticatedAdmin(authentication.adminAuthenticated);

        setLoading(false);
    }, [])
    

    if(loading)
        return null;
    
    return(
        <div className='dashboard'>
            {authenticatedUser ?
                <div className="left">
                    <Sidebar/>
                </div>
                : null
            }
            <div className="right">
                <div className='siteContent'>
                    <div className="contentContainer">
                        <div className="content">
                            <Switch>
                                <UserRoutes path="/user"
                                appProps={{ authenticatedUser }}
                            />
                            <AdminRoutes path="/admin"
                                appProps={{ authenticatedAdmin }}
                            />
                            <Route path="/" render={props => authenticatedUser ?
                                                        <Redirect to='/user/courses' />
                                                        : <Redirect to='/login' /> }/>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
}