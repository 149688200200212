import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import api from '../../services/api';
import "../../utils/axiosInterceptor";
import './styles.css';
import helpers from "../../helpers";
import Loader from 'react-loader-spinner';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';



export default function Certification() {
    const location = useLocation();

    //Utiliza uma função auxiliar para obter da url os parametros com determinados nomes
    const code = jwt.decode(helpers.getQueryParam("code", location));
    const course_id = code.course_id;
    const user_id = code.user_id;
    const [course, setCourse] = useState();
    const [user, setUser] = useState();
    const [signatures, setSignatures] = useState([]);
    const [logos, setLogos] = useState([]);
    const [courseUser, setCourseUser] = useState([]);
    const [loading, setLoading] = useState(true);
    const [topics, setTopics] = useState([])
    const [leftTopics, setLeftTopics] = useState([]);
    const [rightTopics, setRightTopics] = useState([]);
    const history = useHistory();
    const currentUser = Cookies.get('User') ? JSON.parse(Cookies.get('User')) : {};

    const url = window.location.origin + window.location.pathname + `?code=${jwt.sign({ course_id: course_id, user_id: user_id }, 'secret1')}&`;

    function divideTopics() {
        let leftTopicsAux = [];
        let rightTopicsAux = [];
        let counter = 0;
        let lineCounter = 0;
        while (lineCounter < 19 && counter < topics.length) {
            const newLineCounter = (lineCounter + Math.floor((topics[counter].length - 1) / 51) + 1);
            console.log(newLineCounter)
            if (newLineCounter <= 19) {
                leftTopicsAux.push(topics[counter]);
                lineCounter = newLineCounter;
            }
            else {
                leftTopicsAux.push(topics[counter].slice(0, ((19 - lineCounter) * 51) + 1))
                rightTopicsAux.push(topics[counter].slice(((19 - lineCounter) * 51) + 1, topics[counter].length))
                lineCounter = 19;
            }
            counter++;
        }
        for (let i = counter; i < topics.length; i++) {
            rightTopicsAux.push(topics[i]);
        }
        setLeftTopics(leftTopicsAux);
        setRightTopics(rightTopicsAux);
    }
    //formata a data de YYYY-MM-DDT00:00:00.000Z para DD/MM/YYYY
    function formatDate(date) {
        const [year, month, day] = date.split('T')[0].split('-');
        const formattedDate = day + '/' + month + '/' + year;
        return formattedDate;
    }

    useEffect(() => {
        divideTopics();

        // eslint-disable-next-line
    }, [topics])

    //Obtem o usuario e o curso cujos ids foram passados como query
    useEffect(() => {
        setLoading(true);
        api.get(`/course/${course_id}`)
            .then(response => {
                setCourse(response.data.course);
                setSignatures(response.data.signatures);
                setLogos(response.data.logos);
                if (response.data.course.topics) {
                    setTopics(("- ".concat(response.data.course.topics.replace(/\n/g, "\n- "))).split("\n"));
                }
                api.get(`/user/${user_id}`)
                    .then(response2 => {
                        setUser(response2.data.user);
                        api.get(`/courseUser?user_id=${user_id}&course_id=${course_id}`)
                            .then(response => {
                                setCourseUser(response.data.courseUser);
                                setLoading(false);
                            })
                    })
            })

    }, [course_id, user_id])

    useEffect(() => {
        if (user && user.isDefaulter) {
            alert("Aluno inadimplente");
            history.push('/');
        }
    }, [user, history])


    /*function printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
          .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0);
            // pdf.output('dataurlnewwindow');
            pdf.save("download.pdf");
          })
        ;
      }*/

    function printDocument() {
        const printButton = document.getElementById("printButton");
        printButton.style.display = "none";
        document.getElementsByClassName('signatureImg')[0].src = document.getElementsByClassName('signatureImg')[0].src + "?x-request=html"
        /*htmlToImage.toPng(document.getElementById('divToPrint'))
            .then(function (dataUrl) {
                //download(dataUrl, 'my-node.jpeg');
                const pdf = new jsPDF();
                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();
                pdf.addImage(dataUrl, 'JPEG', 0, 0, width, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("download.pdf");
            });
            */
        window.print();
        printButton.style.display = "grid";
    }


    if (loading)
        return (<div className="loaderDiv"> <Loader type="ThreeDots" color={'#863231'} /> </div>);

    return (
        <div className="certification">
            <div className="pageTitle">
                <span>
                    Certificado
                </span>
            </div>
            <div id="divToPrint">
                <div className="pageContent">
                    <div className="certificationContent commonWidth">
                        <div className="certificationBorder">

                            <div className="top">
                                <span>CENTRO ACADÊMICO </span>
                                <span>EMÍLIO RIBAS</span>
                                <span className="certificationText">Certificamos que {user.name}, CPF {user.cpf}, participou do {course.name},
                                    {course.ending_date ? ' de ' + formatDate(course.beginning_date) + ' até ' + formatDate(course.ending_date) : ' em ' + formatDate(course.beginning_date)}, na qualidade de {courseUser?.user_quality}, organizado pelo {course.responsibleBody},
                                    vinculado ao Curso de Medicina do Centro Universitário Padre Albino(FAMECA/UNIFIPA)
                                    {course.workload ? `, com carga horária de ${course.workload} horas` : '.'}.  </span>
                            </div>

                            {signatures[0] && signatures[1] && signatures[2] && !signatures[3] ?
                                <div className="bottomLeft" style={{ left: !signatures[2] && !signatures[3] ? '150px' : '20px' }}>
                                    <div className="signature">
                                        <img className="signatureImg" alt="signature" src={signatures[0].image_path} />
                                        <label>{signatures[0].name}</label>
                                    </div>
                                    <div className="signature">
                                        <img className="signatureImg" alt="signature" src={signatures[1].image_path} />
                                        <label>{signatures[1].name}</label>
                                    </div>
                                </div>
                                : null
                            }
                            {!(signatures[0] && signatures[1] && signatures[2] && !signatures[3]) ?
                                <div className="bottomLeft" style={{ left: !signatures[2] && !signatures[3] ? '150px' : '20px' }}>
                                    {signatures[0] ?
                                        <div className="signature">
                                            <img className="signatureImg" alt="signature" src={signatures[0].image_path} />
                                            <label>{signatures[0].name}</label>
                                        </div>
                                        : null
                                    }
                                    {signatures[2] ?
                                        <div className="signature up">
                                            <img className="signatureImg" alt="signature" src={signatures[2].image_path} />
                                            <label>{signatures[2].name}</label>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                            <div className="bottomMiddle">
                                <div className="logos">
                                    {logos.map(logo => {
                                        return (<img className="logo" alt="logo" src={logo.image_path} ket={'logo' + logo.id} />)
                                    })}
                                </div>
                            </div>
                            {(signatures[0] && signatures[1] && signatures[2] && !signatures[3]) ?

                                <div className="bottomRight" style={{ right: !signatures[3] ? '150px' : '20px' }}>
                                    <div className="signature">
                                        <img className="signatureImg" alt="signature" src={signatures[2].image_path} />
                                        <label>{signatures[2].name}</label>
                                    </div>
                                </div>
                                : null
                            }
                            {!(signatures[0] && signatures[1] && signatures[2] && !signatures[3]) ?
                                <div className="bottomRight" style={{ right: !signatures[2] && !signatures[3] ? '150px' : '20px' }}>
                                    {signatures[3] ?
                                        <div className="signature up">
                                            <img className="signatureImg" alt="signature" src={signatures[3].image_path} />
                                            <label>{signatures[3].name}</label>
                                        </div>
                                        : null
                                    }
                                    {signatures[1] ?
                                        <div className="signature">
                                            <img className="signatureImg" alt="signature" src={signatures[1].image_path} />
                                            <label>{signatures[1].name}</label>
                                        </div>
                                        : null
                                    }
                                </div>
                                : null
                            }
                        </div>
                    </div>
                </div>

                <div className="certificationContent commonWidth">
                    <div className="certificationBorder">
                        {leftTopics ?
                            <h3 style={{ textAlign: 'center' }}>Tópicos Abordados</h3>
                            : null
                        }
                        <div className="topics monospace">
                            <div className="left">
                                {leftTopics.map(topic => {
                                    return (
                                        <div className="topic">
                                            <span>{topic}</span>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="right">
                                {rightTopics.map(topic => {
                                    return (
                                        <div className="topic">
                                            <span>{topic}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="middle">
                            <div className="qrCode">
                                <img alt="qrCode" src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${url}`} />
                            </div>
                            <div className="qrTitle">
                                <span>Emitido pelo Centro Acadêmico Emílio Ribas.</span>
                                <span>Verifique a autenticidade no QR Code</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {currentUser.id === user_id ?
                <button id="printButton" onClick={printDocument}>Download PDF</button>
                : null
            }
        </div>


    )
};