import React from 'react';
import { Route, Switch} from 'react-router-dom';
import Register from '../Register'
import Login from '../Login'
import ForgottenPassword from '../ForgottenPassword';
import ResetPassword from '../ResetPassword';
import LoginLogo from '../../images/login-logo.png';
import LoginBg from '../../images/login-background.png';
import './styles.css';

export default function Authentication() {
  return (
    <div className="authentication">
        <div className="fields">
            <Switch>
                <Route path="/register" component={Register}/>
                <Route path="/login" component={Login}/>
                <Route path="/forgottenPassword" component={ForgottenPassword} />
                <Route path="/resetPassword/:resetPasswordToken" component={ResetPassword}/>
            </Switch>
        </div>
        <div className="side" style={{ backgroundImage: `linear-gradient(179.66deg, #52000A 0.29%, rgba(255, 255, 255, 0) 149.09%), url(${LoginBg})` }}>
            <div className="sideContent">
                <img src={LoginLogo} alt="Centro Acadêmico Emílio Ribas"/>
                <h1>Seus certificados em um só lugar!</h1>
                <span>Bem-vindo(a) à nova plataforma do Centro Acadêmico Emílio Ribas, onde todos os certificados de nossos eventos serão encontrados em um só lugar.</span>
            </div>
        </div>
    </div>
  );
}
