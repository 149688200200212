import React, { useEffect, useState } from 'react';
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Authentication from './pages/Authentication';
import ScrollToTop from './pages/scrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import Certification from "./pages/Certification";
import Auth from './hooks/auth';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure();

function App() {
  // eslint-disable-next-line
  const [authenticatedUser, setAuthenticatedUser] = useState(false);
  const [loading, setLoading] = useState(true);

  
  useEffect(() => {
      const authentication = Auth();
      setAuthenticatedUser(authentication.userAuthenticated);

      setLoading(false);
  }, [])

  if(loading)
    return null;
  //Rotas para login e registro
  //A rota "/" é onde fica de fato o site
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route path="/register" exact render={props => !Auth().userAuthenticated ? 
                                                            <Authentication /> 
                                                          : <Redirect to='/courses' />} />
        <Route path="/login" exact render={props => !Auth().userAuthenticated ? 
                                                            <Authentication /> 
                                                          : <Redirect to='/courses' />} />
        <Route path="/forgottenPassword" exact render={props => !Auth().userAuthenticated ? 
                                                            <Authentication /> 
                                                          : <Redirect to='/courses' />} />
        <Route path="/resetPassword/:resetPasswordToken" exact render={props => !Auth().userAuthenticated ? 
                                                            <Authentication /> 
                                                          : <Redirect to='/courses' />} />
        <Route path="/certification" component={Certification} />
        <Route path="/" component={Dashboard} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
